const paninisData = [
    {
        title: "3 fromages",
        image: require("../image/panini/panini-3fromages.png"),
        paragraphe: "Fromage, gorgonzola, parmesan",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Burger",
        image: require("../image/panini/panini-burger.png"),
        paragraphe: "Fromage, sauce tomate, viande hachée",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Chèvre",
        image: require("../image/panini/panini-chevre.png"),
        paragraphe: "Fromage, crème fraîche, chèvre",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Kebab",
        image: require("../image/panini/panini-kebab.png"),
        paragraphe: "Fromage, sauce tomate, kebab",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Milano",
        image: "../boursin.png",
        paragraphe: "Fromage, crème fraîche, lardons",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Oriental",
        image: require("../image/panini/panini-oriental.png"),
        paragraphe: "Fromage, sauce tomate, merguez",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Panini Nutella",
        image: require("../image/panini/panini-nutella.png"),
        paragraphe: "Nutella",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Poulet",
        image: require("../image/panini/panini-poulet.png"),
        paragraphe: "Fromage, crème fraîche, poulet",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Saumon",
        image: require("../image/panini/panini-saumon.png"),
        paragraphe: "Fromage, crème fraîche, saumon",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Thon",
        image: require("../image/panini/panini-thon.png"),
        paragraphe: "Fromage, sauce tomate, thon",
        price: "paniniprice",
        top: "",
    },
    {
        title: "Vénézia",
        image: require("../image/panini/panini-venezia.png"),
        paragraphe: "Fromage, sauce tomate, jambon",
        price: "paniniprice",
        top: "*",
    },
];

export default paninisData;
